// The client token used for Datadog RUM and Browser Logs ingestion. Per Datadog Support this token is not considered secret and can be hard coded in the application.
export const clientToken = 'pub524ecbafe2e98dd119a89c4aa23a76a0';

// The endpoint for Datadog RUM and Browser Logs ingestion.
export const site = 'datadoghq.com';

// The env, service and version configuration options in Datadog RUM should match the tag values
// we use throughout MyDocBill for the Datadog Unified Service Tagging tags. This allows RUM Sessions
// to be correlated in the Datadog console with traces, logs, metrics and costs on the backend.
// https://docs.datadoghq.com/getting_started/tagging/unified_service_tagging
export const service = 'mdb-ui';
