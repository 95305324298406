import { InternalContext } from '@datadog/browser-logs/cjs/domain/contexts/internalContext';
import {
  DefaultPrivacyLevel,
  RumInitConfiguration,
  datadogRum,
} from '@datadog/browser-rum';

import metadata from '../../../assets/metadata.json';
import { environment } from '../../../environments/environment';
import { isLocalDevelopment } from '../../helpers/helpers';
import { Logger } from '../logger';

import { clientToken, service, site } from './core';

// The Datadog RUM data is viewable in the Datadog RUM console
// https://app.datadoghq.com/rum/performance-monitoring?query=%40application.id%3Afa78f319-ae8c-4b1d-8697-4ce38121ade6
const applicationId = 'fa78f319-ae8c-4b1d-8697-4ce38121ade6';

const isEnabled = !isLocalDevelopment();

export function initialize() {
  datadogRum.init({
    applicationId: applicationId,
    clientToken: clientToken,
    site: site,
    service: service,
    env: environment.name,
    version: metadata.releaseVersion,
    // TODO: Set to reasonable values and possibly make configurable to control costs
    sessionSampleRate: isEnabled ? 100 : 0,
    sessionReplaySampleRate: isEnabled ? 100 : 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: DefaultPrivacyLevel.MASK_USER_INPUT,
    allowedTracingUrls: [window.location.origin, environment.apiUrl],
  } as RumInitConfiguration);

  datadogRum.onReady(() => {
    const logger = new Logger('Datadog RUM');
    logger.debug('Ready');
  });

  const context: InternalContext | undefined = datadogRum.getInternalContext();

  // Intentionally logging the Datadog telemetry to console instead of
  // using the logger for quick access and accuracy when troubleshooting.
  // eslint-disable-next-line no-console
  console.log(
    'Datadog RUM Session URL:',
    context?.session_id
      ? `https://app.datadoghq.com/rum/sessions?query=%40type%3Asession%20%40session.id%3A${context.session_id}`
      : 'Unavailable'
  );
}
