import {
  EnvironmentProviders,
  ErrorHandler,
  Injectable,
  InjectionToken,
  inject,
  makeEnvironmentProviders,
} from '@angular/core';

import { initialize as initializeLogs } from './datadog/logs';
import { initialize as initializeRum } from './datadog/rum';
import { ILogger, Logger } from './logger';

export type LoggerFactory = (name: string) => ILogger;

export const LOGGER_FACTORY = new InjectionToken<LoggerFactory>(
  'LOGGER_FACTORY',
  {
    providedIn: 'root',
    factory: () => loggerFactory,
  }
);

const loggerFactory: LoggerFactory = (name: string) => new Logger(name);

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private logger = inject(LOGGER_FACTORY)('GlobalErrorHandler');

  handleError(error: unknown): void {
    this.logger.error('Unhandled Error', undefined, error);
  }
}

export function provideObservability(): EnvironmentProviders {
  initializeLogs();
  initializeRum();

  return makeEnvironmentProviders([
    {
      provide: LOGGER_FACTORY,
      useFactory: () => loggerFactory,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ]);
}
