import { environment } from '../../environments/environment';
import { featureToggles } from '../services/client-settings/client-settings.service';
import { pendingTransactionsResponse } from '../services/pending-transactions/pending-transactions.service';

export function formatDate(d: string | Date) {
  const date = new Date(d);
  return date.toLocaleDateString('en-US', {
    timeZone: 'America/New_York',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });
}

export function formatPhone(baseMobile: string): string {
  if (baseMobile.length >= 10) {
    const part1 = baseMobile.substring(0, 3);
    const part2 = baseMobile.substring(3, 6);
    const part3 = baseMobile.substring(6, 10);

    return part1 + '-' + part2 + '-' + part3;
  } else {
    return baseMobile;
  }
}

export function formatTime(t: string | Date) {
  const date = new Date(t);
  return date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
  });
}

export function formatToDateString(t: Date) {
  const dateToString = t.toISOString().split('T')[0];
  return dateToString;
}

export function verifyFeatureToggle(
  name: string,
  featureList: featureToggles[]
) {
  for (let i = 0; i < featureList.length; i++) {
    if (name === featureList[i].name && featureList[i].enabled) {
      return true;
    }
  }
  return false;
}

export function calcPaymentBalance(
  balanceAmount: number,
  pendingObj: pendingTransactionsResponse
) {
  if (balanceAmount !== 0) {
    if (pendingObj.pendingTransactions && pendingObj.amount !== 0) {
      return balanceAmount - pendingObj.amount;
    } else {
      return balanceAmount;
    }
  } else {
    return 0;
  }
}

export type TwoDecimalPlaceNumber = number & {
  __brand: 'TwoDecimalPlaceNumber';
};

export function toTwoDecimalPlaceNumber(value: string) {
  const paymentAmountToNumber = Number(value);
  return paymentAmountToNumber.toFixed(2);
}

export function toTwoDecimalPlaceNumberRoundUp(value: string) {
  const paymentAmountToNumber = Number(value);
  // Multiply by 100, apply Math.ceil, then divide by 100 to get the rounded-up two decimal places
  const roundedUpAmount = Math.ceil(paymentAmountToNumber * 100) / 100;
  return roundedUpAmount.toFixed(2);
}

export function isLocalDevelopment(): boolean {
  return (
    environment.webAppHost.includes('127.0.0.1') ||
    environment.webAppHost.includes('localhost') ||
    environment.webAppHost.includes('mydocbill.local')
  );
}
