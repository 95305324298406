import {
  datadogLogs,
  LogsEvent,
  LogsInitConfiguration,
} from '@datadog/browser-logs';

import metadata from '../../../assets/metadata.json';
import { environment } from '../../../environments/environment';
import { isLocalDevelopment } from '../../helpers/helpers';
import { Logger } from '../logger';

import { clientToken, service, site } from './core';

const shouldForwardConsoleLogs = !isLocalDevelopment();

export function initialize() {
  datadogLogs.init({
    clientToken: clientToken,
    site: site,
    service: service,
    env: environment.name,
    name: 'MyDocBill',
    version: metadata.releaseVersion,
    forwardConsoleLogs: shouldForwardConsoleLogs ? 'all' : undefined,
    forwardErrorsToLogs: shouldForwardConsoleLogs ? 'all' : undefined,
    forwardReports: shouldForwardConsoleLogs ? 'all' : undefined,
    telemetrySampleRate: 20,
    beforeSend: (event: LogsEvent) => {
      return LogScrubber.scrub(event);
    },
  } as LogsInitConfiguration);

  // The zotec_product attribute matches how the Zotec standard AWS Tag zotec:product is ingested into Datadog.
  // Setting this attribute allows configuring an index in Datadog called mdb that filters in all logs with
  // zotec_product = MyDocBill. This also allows all logs, traces and metrics with this attribute to be correlated.
  datadogLogs.setGlobalContext({ zotec_product: 'MyDocBill' });

  datadogLogs.onReady(() => {
    const logger = new Logger('Datadog Logs');
    logger.debug('Ready');
  });
}

/**
 * This function should be used to scrub any sensitive data from the logs in the client before they are ingested into Datadog.
 *
 * TODO: scrub any sensitive logs before Datadog ingestion
 */
class LogScrubber {
  /**
   * @param event - The log bound for Datadog to be scrubbed.
   * @returns Returns `true` if the log should be ingested, false if it should be dropped.
   */
  static scrub(event: LogsEvent): boolean {
    if (
      event.message.startsWith('[vite]') ||
      event.message.startsWith('Angular is running in development mode.') ||
      event.message.startsWith('Datadog RUM Session URL:')
    ) {
      return false;
    }

    return true;
  }
}
