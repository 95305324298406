import {
  datadogLogs,
  HandlerType,
  Logger as DatadogLogger,
  LoggerConfiguration,
  StatusType,
} from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { environment } from '../../environments/environment';
import { isNullEmptyOrWhitespace } from '../helpers/string';

export enum LogLevel {
  Debug = 'debug',
  Info = 'info',
  Warn = 'warn',
  Error = 'error',
}

export interface ILogger {
  info(message: string, context?: object): void;
  warn(message: string, context?: object): void;
  debug(message: string, context?: object): void;
  error(message: string, context?: object, maybeError?: unknown): void;
}

export class Logger implements ILogger {
  private logger!: DatadogLogger;

  constructor(name: string | null = null) {
    this.initializeLogger(name);
  }

  public info(message: string, context?: object): void {
    this.log(LogLevel.Info, message, context);
  }

  public warn(message: string, context?: object): void {
    this.log(LogLevel.Warn, message, context);
  }

  public debug(message: string, context?: object): void {
    this.log(LogLevel.Debug, message, context);
  }

  public error(message: string, context?: object, maybeError?: unknown): void {
    const error = this.toError(maybeError);

    // Datadog RUM does not use error logs in anomaly detection for its Error Tracking feature. This statement manually
    // tracks these errors as RUM Session errors and makes them available for analysis in Error Tracking.
    // See https://github.com/DataDog/browser-sdk/issues/1573
    datadogRum.addError(error, context);

    this.log(LogLevel.Error, message, context, error);
  }

  private initializeLogger(name: string | null): void {
    if (isNullEmptyOrWhitespace(name)) {
      this.logger = this.getOrCreateNamedLogger('MyDocBill');
      return;
    }

    this.logger = this.getOrCreateNamedLogger(name!);
  }

  private getOrCreateNamedLogger(name: string): DatadogLogger {
    let logger = datadogLogs.getLogger(name);

    if (!logger) {
      const logLevel = this.getLogLevel();

      logger = datadogLogs.createLogger(name, {
        handler: [HandlerType.console, HandlerType.http],
        level: logLevel,
      } as LoggerConfiguration);
    }

    return logger;
  }

  private getLogLevel(): StatusType {
    switch (environment.logLevel) {
      case LogLevel.Debug:
        return StatusType.debug;
      case LogLevel.Info:
        return StatusType.info;
      case LogLevel.Warn:
        return StatusType.warn;
      case LogLevel.Error:
        return StatusType.error;
      default:
        return LogLevel.Warn;
    }
  }

  private log(
    level: LogLevel,
    message: string,
    context?: object,
    error?: Error
  ): void {
    context = this.normalizeContext(context);

    let status;
    switch (level) {
      case LogLevel.Debug:
        status = StatusType.debug;
        break;
      case LogLevel.Info:
        status = StatusType.info;
        break;
      case LogLevel.Warn:
        status = StatusType.warn;
        break;
      case LogLevel.Error:
        status = StatusType.error;
        break;
      default:
        if (error) {
          status = StatusType.error;
          break;
        }
        status = StatusType.info;
        break;
    }

    this.logger.log(message, context, status, error);
  }

  private toError(maybeError?: unknown): Error {
    if (maybeError === undefined) {
      return new Error();
    }

    if (maybeError instanceof Error) return maybeError;

    return new Error('Unknown Error', {
      cause: new Error(
        typeof maybeError === 'string' ? maybeError : JSON.stringify(maybeError)
      ),
    });
  }

  /**
   * Any application-specific log context is consistently placed under a top-level property named context. This
   * guarantees that application-specific properties are not mixed in with properties in the sink (e.g., Datadog).
   * It also makes it clear when reviewing logs which properties are from the application.
   *
   * Wrong:
   *
   * {
   *   'application_id': '72a5e1e0-6052-4589-ac1c-831841c30f47',
   *   'count: 100,
   *   'date: 1726752488950,
   *   'dd-request-id': 'd1dff5b6-e537-41da-b26d-4766f63fb348',
   *   'http': {...},
   *   'network': {...},
   *   'service': 'mdb-ui',
   *   'session_id': 'bda17490-a0d6-4299-9b47-1310e636333c',
   *   'status': 'error',
   *   'user': 'Joe Smith',
   *   'view': {...}
   * }
   *
   * Correct:
   * {
   *   'application_id': '72a5e1e0-6052-4589-ac1c-831841c30f47',
   *   'context': {
   *     'count: 100,
   * 	   'user': 'Joe Smith'
   *   },
   *   'date: 1726752488950,
   *   'dd-request-id': 'd1dff5b6-e537-41da-b26d-4766f63fb348',
   *   'http': {...},
   *   'network': {...},
   *   'service': 'mdb-ui',
   *   'session_id': 'bda17490-a0d6-4299-9b47-1310e636333c',
   *   'status': 'error',
   *   'view': {...}
   * }
   */
  private normalizeContext(context?: object): object | undefined {
    return context === undefined ? undefined : { context: { ...context } };
  }
}
